import React from "react"
import { withUnpublishedPreview } from "gatsby-source-prismic"
import SEO from "../components/seo"
import Homepage from "./index"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... what a sadness.</p>
    <p>
      <a href="/">
        <button type="button">Return to homepage</button>
      </a>
    </p>
  </>
)

export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    homepage: Homepage,
    prismicHomepage: Homepage,
  },
})
